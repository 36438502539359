<template>
  <div class="m-modal-checkout">
    <SfModal :visible="isVisible" @close="closeModal">
      <SfHeading :title="$t('You must accept our terms of trade before proceeding')" :level="3" />
      {{ $t(defaultContent) }}
      <div class="buttons-wrapper">
        <SfButton class="sf-button--outline sf-button--full-width color-primary" @click="closeModal">
          {{ $t("Decline") }}
        </SfButton>
        <SfButton class="sf-button sf-button--full-width color-secondary" @click="goToCheckout">
          {{ $t("Accept") }}
        </SfButton>
      </div>
    </SfModal>
  </div>
</template>

<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfModal from '~/theme/components/molecules/SfModal/SfModal.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'MModalCheckout',
  components: {
    SfModal,
    SfButton,
    SfHeading
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    defaultContent () {
      return 'Du har endnu ikke accepteret Handelsbetingelserne. Dette er nødvendigt, før du kan fortsætte.'
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    },
    goToCheckout () {
      this.$emit('close', this.modalData.name)
      this.$router.push(localizedRoute('/checkout'));
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.m-modal-checkout {
  .sf-modal {
    --modal-width: 525px;
    z-index: 99999999999;
    position: relative;
    @include for-mobile {
      --modal-width: 100%;
    }
    .buttons-wrapper {
      display: flex;
    }
    .sf-button {
      margin-top: var(--spacer-25);
      &.sf-button--outline {
        margin-right: var(--spacer-15);
      }
    }
  }
}
</style>
<style lang="scss">
  .m-modal-checkout {
    .sf-modal {
      .smartphone-only {
        display: none;
      }
    }
    .sf-modal__container {
      height: fit-content;
    }
  }
</style>
