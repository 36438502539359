<template>
  <div class="sf-language-switcher-wrapper">
    <MModalLanguageSwitcher
      class="sf-language-switcher"
      :is-visible="isLanguageSwitcherOpen"
      :modal-data="getModalData('language-switcher')"
      @close="closeLanguagesModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import MModalLanguageSwitcher from '~/theme/components/molecules/modals/m-modal-language-switcher';

import { mapMobileObserver, unMapMobileObserver } from '~/theme/helpers/mobile-observer';

export default {
  components: {
    MModalLanguageSwitcher
  },
  mixins: [onEscapePress],
  computed: {
    ...mapState({
      isLanguageSwitcherOpen: state => state.ui.languageSwitcher
    }),
    ...mapMobileObserver()
  },
  watch: {
    isLanguageSwitcherOpen: {
      immediate: true,
      handler (val) {
        !val && this.closeLanguagesModal()
      }
    }
  },
  methods: {
    onEscapePress () {
      this.closeLanguagesModal();
    },
    closeLanguagesModal () {
      this.$store.dispatch('ui/toggleLanguageSwitcher', false)
    },
    toggleLanguagesModal () {
      this.$store.dispatch('ui/toggleLanguageSwitcher', false)
    },
    getModalData (name) {
      return {
        name: name
      }
    }
  },
  beforeDestroy () {
    unMapMobileObserver();
  }
};
</script>

<style lang="scss" scoped>

</style>
