<template>
  <div class="sf-checkout-switcher-wrapper">
    <MModalCheckout
      class="sf-checkout-switcher"
      :is-visible="isCheckoutSwitcherOpen"
      @close="closeCheckoutModal"
      :modal-data="getModalData('checkout-switcher')"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import MModalCheckout from '~/theme/components/molecules/modals/m-modal-checkout';

import { mapMobileObserver, unMapMobileObserver } from '~/theme/helpers/mobile-observer';

export default {
  components: {
    MModalCheckout
  },
  mixins: [onEscapePress],
  computed: {
    ...mapState({
      isCheckoutSwitcherOpen: state => state.ui.checkoutSwitcher
    }),
    ...mapMobileObserver()
  },
  watch: {
    isCheckoutSwitcherOpen: {
      immediate: true,
      handler (val) {
        !val && this.closeCheckoutModal()
      }
    }
  },
  methods: {
    onEscapePress () {
      this.closeCheckoutModal();
    },
    closeCheckoutModal () {
      this.$store.dispatch('ui/toggleCheckoutSwitcher', false)
    },
    toggleCheckoutModal () {
      this.$store.dispatch('ui/toggleCheckoutSwitcher', false)
    },
    getModalData (name) {
      return {
        name: name
      }
    }
  },
  beforeDestroy () {
    unMapMobileObserver();
  }
};
</script>

<style lang="scss" scoped>

</style>
