<template>
  <div class="m-modal-language-switcher">
    <SfModal :visible="isVisible" @close="closeModal" :title="$t('Choose your region')">
      <SfImage class="logo-modal" src="/assets/logo_language-switcher.png" />

      <SfHeading class="sf-heading--left desktop-only" :title="$t('Choose your region')" :level="4" />

      <SfList class="sf-language-switcher__container">
        <SfListItem v-for="(storeView, storeCode) in storeViews" :key="storeCode">
          <SfMenuItem
            :label="storeView.i18n.fullCountryName"
            class="sf-language-switcher__item"
            @click.native="setLanguagePopup"
            :link="storeView.url"
          >
            <template #icon>
              <SfImage :src="getCountryFlag(storeView.storeCode)" />
            </template>
          </SfMenuItem>
        </SfListItem>
        <SfListItem :key="'gb'">
          <SfMenuItem
            :label="'United Kingdom'"
            class="sf-language-switcher__item"
            @click.native="setLanguagePopup"
            :link="'https://uk.protein.com/'"
          >
            <template #icon>
              <SfImage :src="'/assets/flags/gb.svg'" />
            </template>
          </SfMenuItem>
        </SfListItem>
      </SfList>

      <SfButton
        class="m-modal-language-switcher__submit sf-button--full-width smartphone-only"
        @click="closeModal"
      >
        {{ $t('Close') }}
      </SfButton>
    </SfModal>
  </div>
</template>

<script>
import config from 'config';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfModal from '~/theme/components/molecules/SfModal/SfModal.vue';
import SfList from '@storefront-ui/vue/src/components/organisms/SfList/SfList.vue';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SfMenuItem from '@storefront-ui/vue/src/components/molecules/SfMenuItem/SfMenuItem.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { setCookie, getCookie } from 'theme/helpers'

export default {
  name: 'MModalLanguageSwitcher',
  components: { SfModal, SfHeading, SfList, SfMenuItem, SfImage, SfButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      selectedCountry: this.getDefaultStoreCode(),
      countryPerColumn: 3
    };
  },
  computed: {
    fullCountryName () {
      return config.i18n.fullCountryName;
    },
    fullLanguageName () {
      return config.i18n.fullLanguageName;
    },
    columns () {
      const viewsCount = Object.keys(this.storeViews).length + 1
      return Math.ceil(viewsCount / this.countryPerColumn)
    },
    storeViews () {
      return Object.keys(config.storeViews).reduce((storeViews, storeCode) => {
        if (this.isValidStoreCode(storeCode)) {
          storeViews[storeCode] = config.storeViews[storeCode];
        }
        return storeViews;
      }, {});
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    },
    isValidStoreCode (storeCode) {
      const storeView = config.storeViews[storeCode];
      return !!(storeView && typeof storeView === 'object' && storeView.i18n);
    },
    setStoreValue (storeCode) {
      this.selectedCountry = storeCode
    },
    getDefaultStoreCode () {
      return currentStoreView().storeCode || config.defaultStoreCode
    },
    getCountryFlag (storeCode) {
      let selectedStore = this.storeViews[storeCode]

      return selectedStore && selectedStore.i18n.flag ? selectedStore.i18n.flag : '';
    },
    submitHandler () {
      if (this.getDefaultStoreCode() === this.selectedCountry) {
        this.closeModal()
      } else {
        let selectedStore = this.storeViews[this.selectedCountry]

        selectedStore && selectedStore.url ? window.location.href = selectedStore.url : this.closeModal();
      }
    },
    setLanguagePopup () {
      if (config.storeSwitchPopup && !getCookie('_language_popup') && (getCookie('declined_cookies') || getCookie('approved_cookies'))) {
        setCookie('_language_popup', true, 1)
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-modal-language-switcher {
  .sf-language-switcher__container {
    margin: var(--spacer-sm) 0 var(--spacer-xl);
    padding: 0;
  }

  .sf-language-switcher__item {
    margin: var(--spacer-sm) 0;
    padding: 0;

    .sf-image {
      margin-right: var(--spacer-xs);
      img {
        width: var(--spacer-30);
      }
    }
  }

  @include for-desktop {
    .sf-modal__content {
      margin: 0 auto;
    }
  }

  .sf-image.logo-modal {
    margin: var(--spacer-base) 0;
  }

  .sf-heading {
    margin-bottom: var(--spacer-base)
  }
}
</style>
